import React, { useState } from "react";
import aboutImage from "../../Images/aboutImage.png";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import { MdLocationOn, MdOutlineMailOutline } from "react-icons/md";
import { FaInternetExplorer } from "react-icons/fa";
import RegisterForm from "../../Components/RegisterForm/RegisterForm";

const ConferanceLogin = () => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section
      className={`about grid grid-cols-12 gap-5 ${
        i18n.language == "en" ? "text-left" : "text-right"
      }`}
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 px-12 py-24 text-center"
          onClick={() => setIsModalOpen(false)}
        >
          <div className="bg-white lg:w-2/3 md:w-2/3 w-full h-fit mt-20 py-12">
            <p className="text-3xl ">الشروط والاحكام</p>
            <p className="text-sm my-4 px-6">
              مرحباً بكم في المؤتمر والمعرض الدولي لإدارة المرافق لعام 2024.
            </p>
            <p className="text-sm lg:px-12 px-6">
              {" "}
              يرجى العلم بأنه عند إكمال عملية التسجيل في الحدث، يرجى ملاحظة أن
              إدارة المؤتمر من الممكن أن تشارك معلومات الاتصال الأساسية الخاصة
              بك والمسجلة في الموقع مع الحاضرين الآخرين في المؤتمر والعارضين
              والجهات الراعية لتسهيل التواصل والعارضين في هذا الحدث. ومن خلال
              المشاركة في هذا الحدث، فإنك توافق على أنه يجوز تصويرك أو ظهورك
              أثناء البث المباشر وتمنح إدارة المؤتمر او الشركة المنظمة الحق في
              استخدام ونشر وحقوق الطبع والنشر لصورتك و/أو ما شابه ذلك و/أو اسمك
              في مقاطع الفيديو و/أو الصور الملتقطة خلال هذا الحدث. للاستخدام من
              قبل إدارة المؤتمر أو الشركة المنظمة في جميع أنحاء العالم، إلى
              الأبد وفي جميع وسائل الإعلام.
            </p>
          </div>
          <button
            className="absolute top-0 right-0 m-4 text-black text-2xl font-bold"
            onClick={() => setIsModalOpen(false)}
          >
            &times;
          </button>
        </div>
      )}

      <div
        // data-aos="fade-right"
        // data-aos-delay="600"
        className=" lg:col-span-12 col-span-12  back-ground"
      >
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text text-center">
          <h3 className="leading-loose">التسجيل والتواصل</h3>
          <p className="mt-6 text-white">powerd by SFMA</p>
        </div>
      </div>

      <div className="col-span-12">
        <div className="lg:grid md:grid grid-cols-12 gap-6 ">
          <div className=" lg:col-span-5 md:col-span-5 col-span-12 lg:my-6 md:my-6 my-16 md:flex md:justify-start md:gap-x-2 sm:flex sm:justify-around sm:gap-x-2 mx-4">
            <div className="w-full">
              <div className="my-4">
                <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                  <FaPhoneAlt className="text-2xl text-[#44add2] my-2" />
                  <p className="text-sm">966565194446+</p>
                  <p className="text-sm">966554222273+</p>
                </div>
              </div>
              <div className="my-4">
                <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                  <FaInternetExplorer className="text-3xl text-[#44add2] my-2" />
                  <p className="text-sm">sfma.sa</p>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="my-4">
                <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                  <MdOutlineMailOutline className="text-3xl text-[#44add2] my-2" />
                  <p className="text-sm">expo@sfma.sa</p>
                </div>
              </div>
              <div className="my-4">
                <div className="flex flex-col items-center justify-center bg-gray-200 rounded-lg h-32 p-4 flex-0 lg:flex-1 w-full text-center">
                  <MdLocationOn className="text-3xl text-[#44add2] my-2" />
                  <p className="text-sm">الرياض - فندق الفيرمونت</p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-7 md:col-span-7 col-span-12">
            <RegisterForm setIsModalOpen={setIsModalOpen} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConferanceLogin;
