import React, { useState } from "react";
import MapImage from "../../Images/Map.jpg";
const MapSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section className="relative">
      <div className="mb-8">
        <img
          src={MapImage}
          className="lg:w-1/2 md:w-7/12 sm:w-3/4  w-full rounded-xl px-12 mx-auto "
          alt="MapImage"
          onClick={() => setIsModalOpen(true)}
        />
        <a
          className="block text-center px-7 py-3 bg-[#007bff] rounded-xl w-fit mx-auto mt-4 text-white cursor-pointer hover:bg-[#1576de]"
          href="../../Images/Map.jpg"
          download={MapImage}
        >
          تحميل الخريطة
        </a>
      </div>
      {isModalOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 px-6"
          onClick={() => setIsModalOpen(false)}
        >
          <img
            src={MapImage}
            className="lg:w-1/2 md:w-7/12 sm:w-3/4 w-full rounded-xl"
            alt="MapImage"
          />
          <button
            className="absolute top-0 right-0 m-4 text-black text-2xl font-bold"
            onClick={() => setIsModalOpen(false)}
          >
            &times;
          </button>
        </div>
      )}
    </section>
  );
};

export default MapSection;
