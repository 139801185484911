import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  firstName: Yup.string().required("من فضلك أدخل الاسم الاول"),
  lastName: Yup.string().required("من فضلك أدخل الاسم الاخير"),
  email: Yup.string()
    .email("من فضلك أدخل البريد الالكتروني")
    .required("من فضلك أدخل البريد الالكتروني"),
  company: Yup.string().required("من فضلك أدخل الشركة"),
  jop_title: Yup.string().required("من فضلك أدخل المسمى الوظيفي"),
  phone: Yup.string().required("من فضلك أدخل رقم الهاتف"),
  website: Yup.string().required("من فضلك أدخل الموقع الالكتروني"),
});

const SponsersForm = () => {
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        jop_title: "",
        phone: "",
        website: "",
        message: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="w-full max-w-2xl mx-auto p-4">
          <div className="flex flex-col mb-4">
            <label>
              البريد الالكترونى <span className="text-red-700 text-xl">*</span>
            </label>
            <Field
              name="email"
              type="email"
              className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
              placeholder="البريد الالكترونى"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label>
                الاسم الأول <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="firstName"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder="الاسم الأول"
              />
              <ErrorMessage
                name="firstName"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label>
                الاسم الاخير <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="lastName"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder="الاسم الاخير"
              />
              <ErrorMessage
                name="lastName"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label>
                الشركة <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="company"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder="الشركة "
              />
              <ErrorMessage
                name="company"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label>
                المسمى الوظيفى <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="jop_title"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder="المسمى الوظيفى"
              />
              <ErrorMessage
                name="jop_title"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label>
                الموقع الالكترونى{" "}
                <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="website"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder="الموقع الالكترونى"
              />
              <ErrorMessage
                name="website"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label>
                رقم الهاتف <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="phone"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
                placeholder="رقم الهاتف"
              />
              <ErrorMessage
                name="phone"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <label>اترك تعليقك</label>
            <Field
              as="textarea"
              name="message"
              className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
              placeholder="Message"
              rows="4"
            />
            <ErrorMessage
              name="message"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="px-6 py-2 bg-[#44add2] text-white rounded-lg hover:bg-[#319cc4]"
              disabled={isSubmitting}
            >
              سجل معنا
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SponsersForm;
