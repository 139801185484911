import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import logoImg from "../../Images/logo-dark.png";
import { useTranslation } from "react-i18next";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [displayNav, setDisplayNav] = useState(false);
  const { t, i18n } = useTranslation();
  const changeLanguageAction = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
  };

  return (
    <header
      className="fixed top-0 w-full nav lg:px-16 md:px-16 px-2 "
      data-aos="fade-bottom"
      data-aos-delay="500"
      dir={i18n.language === "en" ? "ltr" : "rtl"}
    >
      {" "}
      <div className="mx-auto flex justify-between items-center gap-6">
        {/*lg screens nav*/}
        <img
          src={logoImg}
          className="logo lg:col-span-3 md:col-span-3 col-span-6 lg:w-72 md:w-72 sm:w-64 w-48"
          alt="logo"
        />
        <div className="flex justify-between items-center gap-8">
          <ul
            className="justify-between gap-4 lg:flex hidden text-xs font-semibold"
            onClick={() => setDisplayNav(false)}
          >
            <li>
              <Link to="/">{t("common.main")}</Link>
            </li>
            <li>
              <Link to="/About">{t("common.aboutUS")}</Link>
            </li>
            <li>
              <Link to="/conferance">{t("common.conference")}</Link>
            </li>
            <li>
              <Link to="/sponsor">{t("common.sponsors")}</Link>
            </li>
            <li>
              <Link to="/register">{t("common.login")}</Link>
            </li>
            <li>
              <Link to="/price">{t("common.events")}</Link>
            </li>
          </ul>
          <div className="col-span-3 gap-6 items-center lg:flex hidden">
            {i18n.language === "en" ? (
              <div
                className="rounded-md p-2 w-24 btn lg:block md:block hidden"
                style={{ backgroundColor: "#44ADD2" }}
                onClick={() => changeLanguageAction("ar")}
              >
                <p className="text-white font-bold text-xs">العربية</p>
              </div>
            ) : (
              <div
                className="rounded-md p-2 w-24 btn lg:block md:block hidden"
                style={{ backgroundColor: "#44ADD2" }}
                onClick={() => changeLanguageAction("en")}
              >
                <p className="text-white font-bold text-xs">English</p>
              </div>
            )}
          </div>
        </div>

        {/*mobile screens nav*/}
        <div
          className="text-black p-3 rounded-md lg:hidden block cursor-pointer"
          onClick={(e) => {
            setDisplayNav(!displayNav);
          }}
        >
          {!displayNav ? (
            <FaBars className="sm:text-2xl text-xl text-black" />
          ) : (
            <MdOutlineClose className="text-3xl text-black" />
          )}
        </div>
        <ul
          className={` text-black flex flex-col items-center justify-center w-full h-full bg-white nav-list ${
            displayNav ? "Down" : "Up"
          }`}
          onClick={() => setDisplayNav(false)}
        >
          <li className="py-4">
            <Link to="/">{t("common.main")}</Link>
          </li>
          <li className="py-4">
            <Link to="/About">{t("common.aboutUS")}</Link>
          </li>
          <li className="py-4">
            <Link to="/conferance">{t("common.conference")}</Link>
          </li>
          <li className="py-4">
            <Link to="/sponsor">{t("common.sponsors")}</Link>
          </li>
          <li className="py-4">
            <Link to="/register">{t("common.login")}</Link>
          </li>
          <li className="py-4">
            <Link to="/price">{t("common.events")}</Link>
          </li>
          <li className="py-4">
            {i18n.language === "en" ? (
              <div
                className="rounded-md p-2 w-24 btn block"
                style={{ backgroundColor: "#44ADD2" }}
                onClick={() => changeLanguageAction("ar")}
              >
                <p className="text-white font-bold text-xs">العربية</p>
              </div>
            ) : (
              <div
                className="rounded-md p-2 w-24 btn block"
                style={{ backgroundColor: "#44ADD2" }}
                onClick={() => changeLanguageAction("en")}
              >
                <p className="text-white font-bold text-xs">English</p>
              </div>
            )}
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
