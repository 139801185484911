import React from "react";
import "./Conferance.css";
import aboutImage from "../../Images/aboutImage.png";
import speaker from "../../Images/speaker.png";
import { useTranslation } from "react-i18next";
import GetNewUpdates from "../../Components/GetNewUpdates/GetNewUpdates";
import ConferanceForm from "../../Components/ConferanceForm/ConferanceForm";
import { Link } from "react-router-dom";

const Conferance = () => {
  const { t, i18n } = useTranslation();

  return (
    <section
      className={`about grid grid-cols-12 gap-5  ${
        i18n.language == "en" ? "text-left" : "text-right"
      }`}
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <div
        // data-aos="fade-right"
        // data-aos-delay="600"
        className=" lg:col-span-12 col-span-12  back-ground"
      >
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text text-center">
          <h3>المؤتمر</h3>
          <p className="mt-6 text-white">powerd by SFMA</p>
        </div>
      </div>
      <div className="col-span-12">
        <div className="lg:grid md:grid grid-cols-12 px-16 mt-4 mb-10">
          <div className={`col-span-12 my-auto `}>
            <div className="flex justify-center gap-6 mt-4 mb-12">
              <button
                type="submit"
                onClick={() => {
                  // handleSave(values);
                }}
                style={{ backgroundColor: "#44add2" }}
                className="py-2 px-12 lg:w-fit md:w-fit w-full rounded-md text-white font-bold text-sm"
              >
                <Link to="/register">سجل حضورك</Link>
              </button>
              <button
                type="submit"
                onClick={() => {
                  // handleSave(values);
                }}
                style={{ backgroundColor: "#44add2" }}
                className="py-2 px-12 lg:w-fit md:w-fit w-full rounded-md text-white font-bold text-sm"
              >
                <a href="#login-with-us">سجل كمتحدث</a>
              </button>
            </div>
            <p className="text-[#103783] font-bold text-3xl text-center">
              نبذة عن المؤتمر
            </p>
            <p className="text-lg px-4 mt-4 text-[#3598db] text-center font-bold">
              المؤتمر والمعرض الدولي لإدارة المرافق ٢٠٢٤
            </p>{" "}
            <p className="text-lg px-4 text-[#3598db] text-center font-bold">
              تحت شعار : مستقبل مستدام
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-12 px-3">
        <div className="lg:grid md:grid grid-cols-12 px-16 mb-10">
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1">
              نبذة عن المؤتمر:
            </p>
            <p className="text-sm text-[#023047]">
              يسرنا دعوتكم للمشاركة في المعرض والمؤتمر الدولي لإدارة المرافق في
              الرياض، المملكة العربية السعودية. يعد هذا المؤتمر منصة حية
              للابتكار وتبادل المعرفة، حيث يجمع بين خبراء الصناعة، الباحثين،
              ورواد الأعمال لاستكشاف أحدث التطورات وأفضل الممارسات في مجال إدارة
              المرافق.
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              معلومات المؤتمر:
            </p>
            <p className="text-sm text-[#023047]">
              - التواريخ: ٨-١٠ سبتمبر ٢٠٢٤
            </p>
            <p className="text-sm text-[#023047]">
              - الموعد : من 9:00 ص وحتى 5:00 م
            </p>
            <p className="text-sm text-[#023047]">
              - الموقع: فندق فيرمونت، الرياض، المملكة العربية السعودية
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              سبب المشاركة:
            </p>
            <p className="text-sm text-[#023047]">
              يقدم المؤتمر فرصة فريدة للمشاركين لتعزيز معرفتهم وتوسيع شبكاتهم
              الاحترافية. من خلال مجموعة متنوعة من الجلسات وورش العمل، ستتاح
              الفرصة لتطوير المهارات والتفاعل مع أبرز الشخصيات في مجال إدارة
              المرافق.
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              محاور المؤتمر:
            </p>
            <p className="text-sm text-[#023047]">
              1. تكنولوجيا المرافق الذكية: استكشاف أحدث التقنيات والابتكارات في
              إدارة المرافق.
            </p>
            <p className="text-sm text-[#023047]">
              2. إدارة المرافق المستدامة: التركيز على الممارسات الاستدامية في
              تخطيط وتنفيذ المشاريع.
            </p>
            <p className="text-sm text-[#023047]">
              3. الابتكار والتحول الرقمي: كيفية الاستفادة من التحول الرقمي
              وتكنولوجيا المعلومات في مجال إدارة المرافق.
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              محاور المؤتمر:
            </p>
            <p className="text-sm text-[#023047]">
              - فرص للشبكات وبناء علاقات مهنية طويلة الأمد.
            </p>
            <p className="text-sm text-[#023047]">
              - توفير منصة لعرض المشاريع والأبحاث المبتكرة.
            </p>
            <p className="text-sm text-[#023047]">
              - جلسات تفاعلية وورش عمل تطبيقية لتعزيز المهارات العملية.
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              المساهمة في رؤية المملكة:
            </p>
            <p className="text-sm text-[#023047]">
              يعكس المؤتمر التزامنا بدعم رؤية المملكة العربية السعودية، حيث يعزز
              التنويع الاقتصادي وتطوير الكوادر البشرية ويسهم في تحقيق التنمية
              المستدامة حيث يدعم المؤتمر النقاط التالية ضمن رؤية المملكة:
            </p>
            <p className="text-sm text-[#023047]">
              1. تنويع الاقتصاد: من خلال تعزيز تبادل المعرفة وعرض المشاريع
              المبتكرة، يساهم المؤتمر في تنويع الاقتصاد السعودي، مع التركيز على
              القطاعات غير النفطية.
            </p>
            <p className="text-sm text-[#023047]">
              2. تطوير رأس المال البشري: يهدف المؤتمر إلى تعزيز المهارات
              والكفاءات لدى المحترفين في مجال إدارة المرافق، والمساهمة في تطوير
              قوى العمل المؤهلة والتنافسية.
            </p>
            <p className="text-sm text-[#023047]">
              3. الابتكار والتكنولوجيا: من خلال التركيز على الممارسات والتقنيات
              الابتكارية، يتماشى المؤتمر مع رؤية المملكة لتكون مركزًا للابتكار
              والتكنولوجيا في المنطقة.
            </p>
            <p className="text-sm text-[#023047]">
              4. التعاون العالمي: طابع المؤتمر الدولي يعزز التعاون العالمي،
              داعمًا لهدف المملكة في بناء شراكات دولية قوية وشبكات تواصل.
            </p>
            <p className="text-sm text-[#023047]">
              5. التنمية المستدامة: يؤكد المؤتمر على الممارسات والحلول المستدامة
              في إدارة المرافق ، متناسبًا مع التزام المملكة بالاستدامة البيئية
              وإدارة الموارد المسؤولة.
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              الأهداف:
            </p>
            <p className="text-sm text-[#023047]">
              1. تبادل المعرفة: تسهيل تبادل المعرفة الحديثة وأفضل الممارسات
              والحلول الابتكارية في مجال إدارة المرافق.
            </p>
            <p className="text-sm text-[#023047]">
              2. التواصل: توفير منصة للمحترفين والباحثين وقادة الصناعة للتواصل
              وإقامة اتصالات قيمة، تعزيز التعاون والشراكات.
            </p>
            <p className="text-sm text-[#023047]">
              3. تعزيز المهارات: توفير فرص لتطوير المهارات من خلال ورش العمل
              والجلسات التفاعلية ودراسات الحالة لتعزيز كفاءة المشاركين.
            </p>
            <p className="text-sm text-[#023047]">
              4. عرض الابتكار: العرض والاحتفال بالمشاريع والممارسات المبتكرة
              التي تسهم في التقدم في مجال إدارة المرافق.
            </p>
            <p className="text-sm text-[#023047]">
              5. النظرة العالمية: تعزيز النظرة العالمية من خلال جمع خبراء دوليين
              ومحترفين لتبادل رؤى وتجارب متنوعة.
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              الرؤية:
            </p>
            <p className="text-sm text-[#023047]">
              "أن تكون المنصة الرائدة عالميًا لتقدم المعرفة والابتكار والتعاون
              في مجال إدارة المرافق ، مساهمة في التنمية المستدامة ونمو الصناعات
              على مستوى العالم."
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#023047] font-bold text-sm mb-1 mt-6">
              الأهداف:
            </p>
            <p className="text-sm text-[#023047]">
              1. نشر المعرفة: نشر أحدث البحوث والاتجاهات والتطورات في إدارة
              المرافق لجمهور متنوع.
            </p>
            <p className="text-sm text-[#023047]">
              2. تطوير المهني: توفير منصة للتعلم المستمر وتطوير المهارات، لتمكين
              الأفراد من التفوق في مجالاتهم.
            </p>
            <p className="text-sm text-[#023047]">
              3. تعزيز التعاون في صناعة إدارة المرافق: تسهيل التعاون بين أصحاب
              المصلحة في المجال والأكاديميين والجهات الحكومية لمعالجة التحديات
              وخلق فرص النمو.
            </p>
            <p className="text-sm text-[#023047]">
              4. إلهام الابتكار: إلهام الابتكار من خلال الاعتراف بالمشاريع
              والتقنيات والنهج الرائدة في إدارة المرافق.
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p
              className="text-sm text-[#3598db] text-center font-bold mt-8 lg:px-24 md:px-24 sm:px-12"
              id="login-with-us"
            >
              انضموا إلينا في الرياض لفرصة لا مثيل لها للمشاركة والتعلم
              والمساهمة في تقدم إدارة المرافق على مستوى عالمي. معًا دعونا نبتكر
              المستقبل ونحقق التميز في مجالاتنا المختلفة.
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-12">
        <ConferanceForm />
      </div>

      <div className="col-span-12">
        <section className="mt-8" dir={i18n.language == "en" ? "ltr" : "rtl"}>
          <p className="text-3xl px-4 text-[#44add2] text-center font-bold -mt-4 mb-4">
            تعرف على المتحدثين
          </p>
          <div
            className={`why-this-event lg:px-16 md:px-16 px-5  ${
              i18n.language == "en" ? "text-left" : "text-right"
            }`}
          >
            <div className="lg:grid md:grid grid-cols-12 gap-6 mx-auto w-fit">
              <div className="bg-white shadow-lg rounded-lg p-6 max-w-md lg:mx-auto md:mx-auto sm:mx-auto my-8 box col-span-12 mx-4 lg:w-80 md:w-80 sm:w-80 mt-12">
                <div className="relative">
                  <img
                    className=" speaker-img"
                    src={speaker}
                    alt="TestimonialImage"
                  />
                </div>
                <div className="my-2 text-center">
                  <h3 className="text-xl font-bold mb-2 text-[#44add2]">
                    م . عايض القحطاني
                  </h3>
                  <p className="text-gray-800 px-2 font-semibold">
                    رئيس مجلس إدارة جمعية إدارة المرافق السعودية عضو مجلس إدارة
                    الجمعية العالمية لإدارة المرافق عضو لجنة السلامة بغرفة
                    الرياض مقيم سلامة وإدارة مرافق
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="col-span-12 ">
        <GetNewUpdates />
      </div>
    </section>
  );
};

export default Conferance;
