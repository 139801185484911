import React from "react";
import WhatIsEvent from "../../Components/WhatIsEvent/WhatIsEvent";
import DigitalTransformation from "../../Components/DigitalTransformation/DigitalTransformation";
import CoOperation from "../../Components/CoOperation/CoOperation";
import WhyThisEvent from "../../Components/WhyThisEvent/WhyThisEvent";
import SponserMinister from "../../Components/SponserMinister/SponserMinister";
import Creativity from "../../Components/Creativity/Creativity";
import Sponser from "../../Components/Sponser/Sponser";
import "./Home.css";
import EventDetails from "../../Components/EventDetails/EventDetails";
import VideoSection from "../../Components/VideoSection/VideoSection";
import RegisterWithUs from "../../Components/RegisterWithUs/RegisterWithUs";
import GetNewUpdates from "../../Components/GetNewUpdates/GetNewUpdates";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <head>
        <title>الرئيسية</title>
      </head>
      <section className="home">
        <section
          className="homeSec relative flex flex-col justify-center items-center w-full h-full"
          data-aos="fade-top"
          data-aos-delay="700"
        >
          <div className="flex sm:flex-row justify-center items-center absolute bottom-8 left-0 right-0 mx-auto w-full px-4 max-w-2xl -translate-x-5">
            <button className="btn-view-home h-fit w-full sm:w-auto px-4 py-2 sm:px-12 sm:py-3">
              <Link to={"/about"}>اعرض معنا</Link>
            </button>
            <button className="btn-register-home h-fit w-full sm:w-auto px-4 py-2 sm:px-12 sm:py-3">
              <Link to={"/about"}>سجل معنا</Link>
            </button>
          </div>
        </section>
        <SponserMinister />
        <WhatIsEvent />
        <VideoSection />
        <EventDetails />
        <DigitalTransformation />
        <CoOperation />
        <Creativity />
        <WhyThisEvent />
        <Sponser />
        <RegisterWithUs />
        <GetNewUpdates />
      </section>
    </>
  );
};

export default Home;
