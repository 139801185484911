import React from "react";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Sponsers from "./Pages/Sponsers/Sponsers";
import ConferanceLogin from "./Pages/ConferanceLogin/ConferanceLogin";
import Activites from "./Pages/Activites/Activites";
import Conferance from "./Pages/Conferance/Conferance";
import Conditions from "./Pages/Conditions/Conditions";
function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/sponsor" element={<Sponsers />} />
        <Route path="/conferance" element={<Conferance />} />
        <Route path="/register" element={<ConferanceLogin />} />
        <Route path="/price" element={<Activites />} />
        <Route path="/pdf" element={<Conditions />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
