import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";

const validationSchema = Yup.object({
  firstName: Yup.string().required("من فضلك أدخل الاسم الاول"),
  lastName: Yup.string().required("من فضلك أدخل الاسم الاخير"),
  email: Yup.string()
    .email("من فضلك أدخل البريد الالكتروني")
    .required("من فضلك أدخل البريد الالكتروني"),
  company: Yup.string().required("من فضلك أدخل الشركة"),
  jop_title: Yup.string().required("من فضلك أدخل المسمى الوظيفي"),
  phone: Yup.string().required("من فضلك أدخل رقم الهاتف"),
  website: Yup.string().required("من فضلك أدخل الموقع الالكتروني"),
});

const RegisterForm = ({ setIsModalOpen }) => {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        jop_title: "",
        phone: "",
        website: "",
        message: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="w-full max-w-2xl mx-auto p-4">
          <div className="flex flex-col mb-2">
            <label>
              الاسم <span className="text-red-700 text-xl">*</span>
            </label>
            <Field
              name="username"
              className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
            />
            <ErrorMessage
              name="username"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-2">
            <div className="flex flex-col">
              <label>
                البريد الالكترونى{" "}
                <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="email"
                type="email"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label>
                رقم الهاتف <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="phone"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
              />
              <ErrorMessage
                name="phone"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-2">
            <div className="flex flex-col">
              <label>
                المنصب <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="jop_title"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
              />
              <ErrorMessage
                name="jop_title"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label>
                الشركة <span className="text-red-700 text-xl">*</span>
              </label>
              <Field
                name="company"
                className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
              />
              <ErrorMessage
                name="company"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <div className="flex flex-col">
              <label>
                المجال <span className="text-red-700 text-xl">*</span>
              </label>
              <Select
                options={options}
                className="mt-3"
                placeholder="-- اختر المجال --"
              />
              <ErrorMessage
                name="jop_title"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="flex flex-col">
              <label>
                النوع <span className="text-red-700 text-xl">*</span>
              </label>
              <Select
                options={options}
                className="mt-4"
                placeholder="-- اختر النوع --"
              />
              <ErrorMessage
                name="jop_title"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div className="flex flex-col mb-4">
            <label>
              الدولة <span className="text-red-700 text-xl">*</span>
            </label>
            <Select
              options={options}
              className="mt-3"
              placeholder="-- اختر الدولة --"
            />
            <ErrorMessage
              name="jop_title"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label>
              المدينة <span className="text-red-700 text-xl">*</span>
            </label>
            <Field
              name="username"
              className="w-full bg-gray-100 rounded-lg border border-gray-200 outline-none p-2"
            />
            <ErrorMessage
              name="username"
              component="div"
              className="text-red-500 text-sm"
            />
          </div>
          <div className="flex items-center gap-2 -mt-4 mb-4 ">
            <input name="checkbox" type="checkbox" className=" w-fit" />
            <label htmlFor="checkbox" className="cursor-pointer text-sm">
              اوافق على الشروط والاحكام{" "}
              <span
                className="text-gray-400 text-xs underline"
                onClick={() => setIsModalOpen(true)}
              >
                اطلع على الشروط والاحكام
              </span>
            </label>
          </div>
          <div className="mb-10">
            <button
              type="submit"
              className="px-6 py-2 bg-[#44add2] text-white rounded-lg hover:bg-[#319cc4]"
              disabled={isSubmitting}
            >
              سجل معنا
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
