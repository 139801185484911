import React from "react";
import "./About.css";
import aboutImage from "../../Images/aboutImage.png";
import aboutImage1 from "../../Images/about-image1.png";
import aboutImage2 from "../../Images/about-image2.png";
import aboutImage3 from "../../Images/about-image3.png";
import { useTranslation } from "react-i18next";
import RegisterWithUs from "../../Components/RegisterWithUs/RegisterWithUs";
import GetNewUpdates from "../../Components/GetNewUpdates/GetNewUpdates";

const About = () => {
  const { t, i18n } = useTranslation();

  return (
    <section
      className="about grid grid-cols-12 gap-5 text-center"
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <div
        // data-aos="fade-right"
        // data-aos-delay="600"
        className=" lg:col-span-12 col-span-12  back-ground"
      >
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text">
          <h3>نبذة عنا</h3>
          <p className="mt-6 text-white">powerd by SFMA</p>
        </div>
      </div>
      <div
        // data-aos="fade-left"
        // data-aos-delay="600"
        className="col-span-12"
      >
        <div className="lg:grid md:grid grid-cols-12 px-16 mt-16 mb-10">
          <div
            className={`lg:col-span-3 md:col-span-3 col-span-12 my-auto  ${
              i18n.language == "en" ? "text-left" : "text-right"
            }`}
          >
            <p
              style={{
                color: "#44add2",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              نبذة عنا
            </p>
            <p
              style={{
                color: "#103783",
                fontWeight: "bold",
                fontSize: "24px",
              }}
            >
              عن SFMA EXPO 2024
            </p>
          </div>
          <div className={`lg:col-span-9 md:col-span-9 col-span-12 `}>
            <p
              className={`text-sm leading-loose ${
                i18n.language == "en" ? "text-left" : "text-right"
              }`}
            >
              المؤتمر والمعرض الدولي لإدارة المرافق هو الأول من نوعه، يهدف إلى
              توفير منصة عالميــة موحــدة تجمــع جميــع الأطـراف من قطاعات
              ومؤسسات وخبراء وأكاديمييـن وصنـاع القـرار والمهتمين لمشاركة
              المعرفة وأفضل الممارسات العالمية وتبادل الخبرات حول أحدث التطورات
              والممارسات المتعلقة بإدارة المرافق ، والخروج بحلول إبداعية تساعد
              على مواجهة التحديات المشتركة وتعزيز الاستدامة والكفاءة، والتركيز
              على أداء أبرز الجهات والــقطاعــــات، بمـــشــاركــــة أكثـــر
              مــن 50 متحـــدث وخبيــــر، الـذي يخــدم فـــي مجمله أهداف ورؤية
              المملكة 2030. تتميز المنظمات التي تسعى إلى تحقيق التميز والكفاءة
              بالمنهجية في إدارة أعمالها وعملياتها المرتبطة بالمرافق، واستثمارها
              في تطوير حلول أكثر شمولية لدفع التحول وفقا للاتجاهات العالمية.
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-8 col-span-12 px-16">
        <div className="card text-center p-4">
          <img
            src={aboutImage1}
            alt="Card 1"
            className="mx-auto mb-4 w-24 h-24 object-cover"
          />
          <h2
            className="text-lg font-semibold mb-2"
            style={{
              color: "rgb(68, 173, 210)",
            }}
          >
            تعـرف
          </h2>
          <p
            style={{
              color: "rgb(2, 48, 71)",
            }}
          >
            تعرف معنا علـــى فـــرص الابتكـــار في العمليـــات والإجــراءات
            لتحقيق التميز في إدارة المرافق..
          </p>
        </div>
        <div className="card text-center p-4">
          <img
            src={aboutImage2}
            alt="Card 2"
            className="mx-auto mb-4 w-24 h-24 object-cover"
          />
          <h2
            className="text-lg font-semibold mb-2"
            style={{
              color: "rgb(68, 173, 210)",
            }}
          >
            اطّلـع
          </h2>
          <p
            style={{
              color: "rgb(2, 48, 71)",
            }}
          >
            اطلع معنا علـــى مختلـــف التجــارب والممارســــات فـي مجال إدارة
            المرافـق .
          </p>
        </div>
        <div className="card text-center p-4">
          <img
            src={aboutImage3}
            alt="Card 3"
            className="mx-auto mb-4 w-24 h-24 object-cover"
          />
          <h2
            className="text-lg font-semibold mb-2"
            style={{
              color: "rgb(68, 173, 210)",
            }}
          >
            استفد
          </h2>
          <p
            style={{
              color: "rgb(2, 48, 71)",
            }}
          >
            بحضورك ومشاركتك معنا ستتمكن من الإستفادة مــن الخبـــرات المحليـــة
            والعالميـــة فـــي مجـــال إدارة المـــرافق .
          </p>
        </div>
      </div>
      <div className="col-span-12">
        <RegisterWithUs />
      </div>
      <div className="col-span-12 -mt-16">
        <GetNewUpdates />
      </div>
    </section>
  );
};

export default About;
