import React from "react";
import "./Sponsers.css";
import aboutImage from "../../Images/aboutImage.png";
import aboutImage1 from "../../Images/about-image1.png";
import aboutImage2 from "../../Images/about-image2.png";
import aboutImage3 from "../../Images/about-image3.png";
import sponsersImage1 from "../../Images/sponsers-image1.png";
import sponsersImage2 from "../../Images/sponsers-image2.png";
import sponsersImage3 from "../../Images/sponsers-image3.png";
import WhyThisEvent1 from "../../Images/WhyThisEvent1.png";
import WhyThisEvent2 from "../../Images/WhyThisEvent2.png";
import WhyThisEvent3 from "../../Images/WhyThisEvent3.png";

import { useTranslation } from "react-i18next";
import RegisterWithUs from "../../Components/RegisterWithUs/RegisterWithUs";
import GetNewUpdates from "../../Components/GetNewUpdates/GetNewUpdates";
import EventDetails from "../../Components/EventDetails/EventDetails";
import Sponser from "../../Components/Sponser/Sponser";
import MapSection from "../../Components/MapSection/MapSection";
import SponsersForm from "../../Components/SponsersForm/SponsersForm";

const Sponsers = () => {
  const { t, i18n } = useTranslation();

  return (
    <section
      className="about grid grid-cols-12 gap-5 text-center"
      dir={i18n.language == "en" ? "ltr" : "rtl"}
      style={{ textAlign: i18n.language == "en" ? "left" : "right" }}
    >
      <div
        // data-aos="fade-right"
        // data-aos-delay="600"
        className=" lg:col-span-12 col-span-12  back-ground"
      >
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text text-center">
          <h3> اعرض معنا</h3>
          <p className="mt-6 text-white">powerd by SFMA</p>
        </div>
      </div>
      <div className="col-span-12 -mt-5" style={{ backgroundColor: "#f5f5f5" }}>
        <div className="col-span-12">
          <GetNewUpdates fromSponsers={true} />
        </div>
        <div
          // data-aos="fade-left"
          // data-aos-delay="600"
          className="col-span-12"
        >
          <div className="lg:grid md:grid grid-cols-12 px-16 mb-10 -mt-6">
            <div
              className={`lg:col-span-5 md:col-span-5 col-span-12   ${
                i18n.language == "en" ? "text-left" : "text-right"
              }`}
            >
              <p
                style={{
                  color: "#103783",
                  fontWeight: "bold",
                  fontSize: "30px",
                }}
              >
                عزز من تواجدك وترسيخ علامتك التجارية
              </p>
            </div>
            <div className={`lg:col-span-7 md:col-span-7 col-span-12 mx-4`}>
              <p
                className={`text-sm leading-loose ${
                  i18n.language == "en" ? "text-left" : "text-right"
                }`}
              >
                نحيطكم أنه بمجرد التواصل معنا يمكنكم حجز أحد أجنحة المعرض
                المصاحب لمؤتمر إدارة المرافق ، والحصول على فرصة مهمة للترويج
                لعلامتكم التجارية والخدمات التي تقدمونها، والتعرف على منافسيكم
                في السوق السعودي والذي يعتبر أكبر أسواق منطقة الشرق الأوسط ،
                والقيام بأعمال تجارية وعقد العديد من الصفقات والعقود، وكسب
                المزيد من العملاء وشركاء الأعمال، وسيقوم أحد أعضاء الفريق
                المسئول عن التنظيم بالتواصل معكم لاستكمال إجراءات حجز جناحكم،
                وتقديم المشورة لكم، والرد على جميع أسئلتكم، وسيساعدنا ذلك على
                خدمتكم بشكل أفضل. .
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap- mx-16 mb-16 gap-8">
          <div className="card bg-white shadow-lg p-4 rounded-xl">
            <div className="flex items-center mb-4">
              <img
                src={sponsersImage1}
                alt="Card 1"
                className="w-16 h-16 object-cover mr-4"
              />
              <h2 className="text-xl font-semibold text-[#44add2]">
                الحدث الأكبر من نوعه
              </h2>
            </div>
            <p className="text-[#212229] text-center px-2 pb-2">
              هو المعرض والمؤتمر المتخصص لمجال إدارة المرافق في المنطقة. يهدف
              المؤتمر إلى توفير منصة لا مثيل لها لاستكشاف أحدث المنتجات
              والابتكارات والتقدم التكنولوجي في مجال إدارة المرافق على مستوى
              عالمي. سيجمع قادة المجال معًا لتقديم رؤى حول بناء نماذج أعمال مرنة
              مع عمليات رقمية مؤتمتة رشيقة.
            </p>
          </div>
          <div className="card bg-white shadow-lg p-4 rounded-xl">
            <div className="flex items-center mb-4">
              <img
                src={sponsersImage2}
                alt="Card 2"
                className="w-16 h-16 object-cover mr-4"
              />
              <h2 className="text-xl font-semibold text-[#44add2]">
                عرض أحدث التقنيات والأجهزة
              </h2>
            </div>
            <p className="text-[#212229] text-center px-2 pb-2">
              يهدف المؤتمر إلى عرض أحدث التقنيات والأجهزة في مجال إدارة المرافق.
              حيث تساهم التكنولوجيا في قطاع إدارة المرافق بشكل كبير في تحسين
              الكفاءة التشغيلية وتحقيق أهداف الصيانة والتشغيل يمكن للتقنيات
              الحديثة أن تساعد في تجنب مشكلات مثل أعطال المعدات، وإهمال الصيانة،
              واستخدام غير كفؤ للموارد. توظف التكنولوجيا قدرتها على قياس وتحليل
              واكتشاف الأنماط في مجموعات البيانات الكبيرة لتحقيق الكفاءة
              والتحسين المستمر
            </p>
          </div>
          <div className="card bg-white shadow-lg p-4 rounded-xl">
            <div className="flex items-center mb-4">
              <img
                src={sponsersImage3}
                alt="Card 3"
                className="w-16 h-16 object-cover mr-4"
              />
              <h2 className="text-xl font-semibold text-[#44add2]">
                بناء علاقات استثمارية مثمرة
              </h2>
            </div>
            <p className="text-[#212229] text-center px-2 pb-2">
              يوفر هذا الحدث منصة مثالية لبناء علاقات استثمارية قوية ومثمرة.
              يجمع هذا المؤتمر بين مختلف الشركات والمستثمرين في قطاع إدارة
              المرافق، ويوفر فرصًا للتواصل والتعاون. لبناء علاقات استثمارية
              ناجحة، يجب أن يكون لدينا الوعي بأهمية التواصل والثقة. يمكن أن يكون
              الاستثمار في مشروعات مشتركة أو تبادل المعرفة والخبرات بين الشركات
              والمستثمرين أساسًا لبناء علاقات قوية. بالإضافة إلى ذلك، يجب أن
              نكون مستعدين للتعلم والتكيف مع التغيرات في سوق إدارة المرافق. من
              خلال تبني استراتيجيات مستدامة والاستفادة من الفرص الناشئة، يمكننا
              بناء علاقات استثمارية تعزز من نجاح مشاريعنا وتعزز من تطور القطاع.
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-12 ">
        <EventDetails />
      </div>
      <div className="col-span-12 ">
        <p
          style={{
            color: "#44add2",
            fontWeight: "bold",
          }}
          className="text-2xl mt-6 mb-2 text-center"
        >
          لماذا ترعى هذا الحدث ؟
        </p>
        <p
          style={{
            fontWeight: "bold",
          }}
          className="text-sm text-center text-[#212529] lg:mx-40 md:mx-28 px-4 mb-6"
        >
          إن اختياركم إحدى فئات رعاية المؤتمر والمعرض الدولي لإدارة المرافق
          سيمنحكم العديد من المميزات وفق كل فئة ويمكن معرفة مميزات كل فئة عبر
          التواصل معنا واستعراض مميزات الرعاة مفصلة.{" "}
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-x-8 col-span-12 px-16">
          <div className="card p-4">
            <img
              src={WhyThisEvent1}
              alt="Card 1"
              className="mx-auto mb-4 w-full object-cover"
            />
            <h2
              className="text-lg font-bold mb-2"
              style={{
                color: "rgb(68, 173, 210)",
              }}
            >
              موقع المعرض
            </h2>
            <p
              style={{
                color: "#212529",
              }}
            >
              يقــام المعــرض فــي مدينة الريـــاض ، تحديداً ( فندق فيرمونت
              الرياض ) وهــذا يعتبـــر موقعًــا مركزيًــا وملائمًا في المملكة
              العربية السعودية.
            </p>
          </div>
          <div className="card p-4">
            <img
              src={WhyThisEvent2}
              alt="Card 1"
              className="mx-auto mb-4 w-full object-cover"
            />
            <h2
              className="text-lg font-bold mb-2"
              style={{
                color: "rgb(68, 173, 210)",
              }}
            >
              الإستهداف
            </h2>
            <p
              style={{
                color: "#212529",
              }}
            >
              يستهــدف المعــرض جمهــورًا محليًــا ودوليًـا، مما يعنـــي أنه
              يسعـى لاجتذاب الزوار والعارضين من الداخل والخارج.
            </p>
          </div>
          <div className="card p-4">
            <img
              src={WhyThisEvent3}
              alt="Card 1"
              className="mx-auto mb-4 w-full object-cover"
            />
            <h2
              className="text-lg font-bold mb-2"
              style={{
                color: "rgb(68, 173, 210)",
              }}
            >
              المشاركون
            </h2>
            <p
              style={{
                color: "#212529",
              }}
            >
              يضـــم المعـــرض عــدد مـن المشاركيــــن مـن القطاعـات العامة
              والخاصة، ومنصة للقــاء خبــــــراء وأكـــاديمييـن وصنــاع القــرار
              و المهتمين بمجال إدارة المرافق.
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-12 mt-4">
        <Sponser />
      </div>

      <div className="col-span-12">
        <RegisterWithUs />
      </div>
      <div className="col-span-12">
        <MapSection />
      </div>
      <div className="col-span-12 mb-6">
        <SponsersForm />
      </div>
    </section>
  );
};

export default Sponsers;
