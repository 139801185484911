import React from "react";
import "./Conditions.css";
import aboutImage from "../../Images/aboutImage.png";
import { useTranslation } from "react-i18next";

const Conditions = () => {
  const { t, i18n } = useTranslation();

  return (
    <section
      className={`about grid grid-cols-12 gap-5  ${
        i18n.language == "en" ? "text-left" : "text-right"
      }`}
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <div
        // data-aos="fade-right"
        // data-aos-delay="600"
        className=" lg:col-span-12 col-span-12  back-ground"
      >
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text text-center">
          <h3>التعليمات والشروط</h3>
          <p className="mt-6 text-white">powerd by SFMA</p>
        </div>
      </div>

      <div className="col-span-12 px-3">
        <div className="lg:grid md:grid grid-cols-12 px-16 mb-10">
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#212529] font-bold text-3xl mb-8 mt-8">
              دعوة لتقديم العروض التقديمية
            </p>
          </div>
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#3598db] font-bold text-sm mb-1">
              نبذة عن المؤتمر والمعرض الدولي لإدارة المرافق (حدث بتنظيم واشراف
              جمعية إدارة المرافق السعودية SFMA)
            </p>
            <p className="text-sm text-[#023047] my-2 leading-loose">
              يعد المؤتمر والمعرض الدولي لإدارة المرافق الأول من نوعه في
              المنطقة، ويهدف إلى توفير منصة عالمية موحدة تجمع جميع الأطراف من
              مختلف القطاعات والمؤسسات والخبراء والأكاديميين وصناع القرار
              والمهتمين لتبادل المعرفة وأفضل الممارسات العالمية، وتبادل الخبرات
              حول أحدث التطورات والممارسات المتعلقة بإدارة المرافق، والتوصل إلى
              حلول إبداعية تساعد في مواجهة التحديات المشتركة. تعزيز الاستدامة
              والكفاءة والتركيز على أداء أبرز الجهات والقطاعات، بمشاركة أكثر من
              50 متحدثا وخبيرا، وهو ما يخدم بشكل عام أهداف ورؤية المملكة 2030.
            </p>
          </div>
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#3598db] font-bold text-sm mb-1 mt-6">
              معلومات المؤتمر:
            </p>
            <p className="text-sm text-[#023047] my-2">
              - التاريخ: 8-10 سبتمبر 2024
            </p>
            <p className="text-sm text-[#023047]">
              - الموقع: فيرمونت، الرياض، المملكة العربية السعودية
            </p>
          </div>
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#3598db] font-bold text-sm mb-1 mt-6 my-2">
              محاور المؤتمر:
            </p>
            <p className="text-sm text-[#023047] my-2">
              1. تكنولوجيا المرافق الذكية: استكشاف أحدث التقنيات والابتكارات في
              إدارة المرافق.
            </p>
            <p className="text-sm text-[#023047] my-2">
              2. الإدارة المستدامة للمرافق: التركيز على الممارسات المستدامة في
              تخطيط المشاريع وتنفيذها.
            </p>
            <p className="text-sm text-[#023047]">
              3. الابتكار والتحول الرقمي: كيفية الاستفادة من التحول الرقمي
              وتكنولوجيا المعلومات في المنشآت.
            </p>
          </div>
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#3598db] font-bold text-sm mb-1 mt-6 my-2">
              دعوة لتقديم العروض التقديمية:
            </p>
            <p className="text-sm text-[#023047] leading-loose">
              تدعوك الجمعية السعودية لإدارة المرافق (SFMA) لمشاركة معرفتك
              وخبراتك كمقدم عرض في مؤتمر ومعرض SFMA الدولي لإدارة المرافق 2024.
              إنه الوقت المناسب لمشاركة خبرتك وأبحاثك على المستوى الدولي.
            </p>
          </div>
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#e03e2d] font-bold lg:text-xl md:text-xl mb-1 mt-6 text-center my-2">
              الموعد النهائي لتقديم العرض التقديمي هو: السبت 10 أغسطس 2024
            </p>
            <p className="text-sm text-[#023047] font-bold leading-loose">
              ليكون العرض التقديمي مقبولا فيجب ان يكون في القضايا المتعلقة
              بصناعة إدارة المرافق. بالإضافة إلى الاتجاهات الجديدة في الاستدامة
              والذكاء الاصطناعي والأمن السيبراني وتكنولوجيا إدارة المرافق
              ومستقبل إدارة المرافق. من الأولويات القصوى توفير تبادل المعارف
              للتغلب على التحديات التي تواجه مقدمي الخدمات والمالكين في صناعة
              إدارة المرافق.
            </p>
          </div>
          <div className={`col-span-12 my-auto font-semibold`}>
            <p className="text-[#3598db] font-bold text-sm mb-1 mt-12">
              يرجى الاطلاع على الملفين المرفقين لمعرفة شروط ومتطلبات قبول العرض
              التقديمي
            </p>
            <div className="flex lg:justify-start md:justify-start sm:justify-start justify-center gap-6 mt-4">
              <button
                type="submit"
                onClick={() => {
                  // handleSave(values);
                }}
                style={{ backgroundColor: "#44add2" }}
                className="py-2 px-6 lg:w-fit md:w-fit w-full rounded-md text-white font-bold text-sm"
              >
                <a
                  href={
                    i18n.language == "en"
                      ? "/files/Call for presentation - International Facility Management Conference and Exhibition 2024.pdf"
                      : "/files/Arabic Call for presentation - International Facility Management Conference and Exhibition 2024.pdf"
                  }
                  download
                >
                  الملف الأول
                </a>
              </button>
              <button
                type="submit"
                onClick={() => {
                  // handleSave(values);
                }}
                style={{ backgroundColor: "#44add2" }}
                className="py-2 px-6 lg:w-fit md:w-fit w-full rounded-md text-white font-bold text-sm"
              >
                <a href="/files/PDF 2 Ar.pdf" download>
                  الملف الثاني
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Conditions;
