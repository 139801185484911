import React from "react";
import "./Activites.css";
import aboutImage from "../../Images/aboutImage.png";
import dinnerImage from "../../Images/dinner.png";

import { useTranslation } from "react-i18next";
import GetNewUpdates from "../../Components/GetNewUpdates/GetNewUpdates";
import { Link } from "react-router-dom";

const Activites = () => {
  const { t, i18n } = useTranslation();

  return (
    <section
      className={`about grid grid-cols-12 gap-5  ${
        i18n.language == "en" ? "text-left" : "text-right"
      }`}
      dir={i18n.language == "en" ? "ltr" : "rtl"}
    >
      <div
        // data-aos="fade-right"
        // data-aos-delay="600"
        className=" lg:col-span-12 col-span-12  back-ground"
      >
        <img
          src={aboutImage}
          className="about-img col-span-12"
          alt="aboutImage"
        />
        <div className="text text-center">
          <h3>الفعاليات</h3>
          <p className="mt-6 text-white">powerd by SFMA</p>
        </div>
      </div>
      <div className="col-span-12">
        <div className="lg:grid md:grid grid-cols-12 px-16 mt-4 mb-10">
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#44add2] font-bold text-xl mb-3 text-center">
              المؤتمر والمعرض الدولي لإدارة المرافق
            </p>
            <p className="text-[#103783] font-bold text-3xl text-center">
              تعرف على الفعاليات
            </p>
            <p className="text-sm px-4 mt-4">
              إحدى الإضافات الجديدة والقيمة للمؤتمر هذا العام هي منصة تبادل
              الأعمال، وهي منصة تفاعلية للاجتمعات ذات مقاعد محدودة ، والتي تتيح
              للشركات التواصل المباشر مع الجمهور المستهدف لإعلامهم عن أنشطتها
              وآخر مستجداتها؛ مما يؤدي إلى خلق فرص للتعاون وتحقيق الأهداف.
              بالإضافة إلى ذلك، يتميز مؤتمر هذا العام بوجود منصـــة عـروض
              الشـركـات، وهـي منصـة تتيـح للشـركــات عمـــل عــــرض تقــــديمي
              ذو محتوى تجاري عن منتجاتهم وخدماتهم
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-12 px-3">
        <div className="lg:grid md:grid grid-cols-12 px-16 mt-4 mb-10">
          <div className={`col-span-12 my-auto `}>
            <p className="text-[#44add2] font-bold text-xl mb-3">عزز حضورك</p>
            <p className="text-[#103783] font-bold text-3xl">من خلال مشاركتك</p>
            <p className="text-sm mt-4">
              استفد من هذه الفرصة للحصول على تجربة رائعة ومختلفة مثل الجولات
              الميدانية للمنشآت الحديثة بالإضافة الى المزايا الأخرى.
            </p>
          </div>
        </div>
        <div className="px-16 lg:flex md:flex sm:flex justify-around items-center ">
          <div className="flex items-center gap-6">
            <img src={dinnerImage} className="w-28" alt="dinnerImage" />
            <p className="text-xl font-bold">عشاء</p>
          </div>
          <div className="flex items-center gap-6 lg:mt-0 md:mt-0 sm:mt-0 mt-10">
            <svg
              width="79"
              height="44"
              viewBox="0 0 79 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.192 34.3978L33.5738 34.0101L33.0309 33.973C24.5676 33.3942 16.347 31.6378 10.2509 28.7962C4.13956 25.9475 0.25 22.0537 0.25 17.2357C0.25 14.5231 1.48283 12.1088 3.65622 10.003C5.83273 7.89421 8.94401 6.10451 12.6683 4.65616C20.1162 1.75977 29.9588 0.250346 39.5002 0.250346C49.0417 0.250346 58.8842 1.75965 66.3319 4.65594C70.0562 6.10423 73.1674 7.89389 75.3439 10.0026C77.5172 12.1084 78.75 14.5227 78.75 17.2353C78.75 20.8485 76.5366 24.1576 72.366 26.8974C68.1959 29.6367 62.1009 31.7825 54.4292 33.0636C52.9027 33.2908 51.3621 32.2232 50.9719 30.7751C50.7472 29.1585 51.7528 27.6525 53.3395 27.4265L53.3395 27.4266L53.345 27.4257C59.643 26.3867 64.4954 24.7222 67.7806 22.9024C69.4228 21.9928 70.6819 21.0397 71.5337 20.099C72.3827 19.1613 72.847 18.213 72.847 17.3184C72.847 16.0834 71.9655 14.771 70.4239 13.5149C68.8711 12.2497 66.5998 11.0003 63.705 9.89653C57.9131 7.68805 49.5871 6.04773 39.4168 6.04773C29.2879 6.04773 20.962 7.68805 15.16 9.89643C12.2601 11.0002 9.981 12.2495 8.42182 13.5145C6.87406 14.7703 5.9866 16.0828 5.9866 17.3184C5.9866 18.397 6.67266 19.5365 7.88046 20.6413C9.09539 21.7527 10.8749 22.8637 13.1507 23.8855C17.7041 25.9298 24.2822 27.6337 32.4124 28.2569L33.0878 28.3087L32.608 27.8305L28.7827 24.0189C27.6334 22.8737 27.6334 21.0437 28.7827 19.8985C29.9326 18.7528 31.7706 18.7528 32.9204 19.8985L32.9212 19.8993L42.4005 29.2619C42.4007 29.2621 42.4008 29.2622 42.4009 29.2623C43.5498 30.4076 43.5497 32.2373 42.4005 33.3823L42.399 33.3839L33.0087 42.9063C32.3813 43.4512 31.6914 43.75 30.9349 43.75C30.1666 43.75 29.3994 43.4427 28.8662 42.9115C27.7169 41.7663 27.7169 39.9363 28.8663 38.7911L28.8679 38.7894L33.192 34.3978Z"
                fill="url(#paint0_linear_459_1483)"
                stroke="white"
                stroke-width="0.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_459_1483"
                  x1="39.5"
                  y1="0"
                  x2="39.5"
                  y2="44"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#00B2A9" />
                  <stop offset="1" stop-color="#105BE6" />
                </linearGradient>
              </defs>
            </svg>

            <p className="text-xl font-bold">جولات المنشأة</p>
          </div>
        </div>
      </div>

      <div className="col-span-12">
        <section className="mt-8" dir={i18n.language == "en" ? "ltr" : "rtl"}>
          <div
            className={`why-this-event lg:px-16 md:px-16 px-5  ${
              i18n.language == "en" ? "text-left" : "text-right"
            }`}
          >
            <div className="lg:grid md:grid grid-cols-12 gap-6 mx-auto w-fit">
              <div className=" lg:col-span-6 md:col-span-6 col-span-12 lg:px-16 md:px-16 px-5 my-auto text-white">
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                  className="text-2xl"
                >
                  عزز حضورك
                </p>
                <p className=" text-sm my-3">
                  استفد من هذه الفرصة للحصول على تجربة رائعة ومختلفة مثل الجولات
                  الميدانية للمنشآت الحديثة بالإضافة الى المزايا الأخرى.
                </p>
              </div>
              <div className=" lg:col-span-6 md:col-span-6 col-span-12 lg:block md:block flex justify-center">
                <button className="btn-why-this-event">
                  <Link to={"/register"}>تواصل معنا</Link>
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="col-span-12 ">
        <GetNewUpdates />
      </div>
    </section>
  );
};

export default Activites;
